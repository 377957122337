export default {
  themes: {
    // global theme for the app
    globalTheme: 'light', // light | dark

    // dark theme colors
    dark: {
      textbase: '#999999',
      background: '#05090c',
      surface: '#111b27',
      primary: '#1976D2',
      secondary: '#bbdefb',
      accent: '#d27619',
      error: '#FF5252',
      info: '#2196F3',
      success: '#05c075',
      warning: '#FFC107',
    },
    // dark: {
    //   textbase: '#999999',
    //   background: '#05090c',
    //   surface: '#111b27',
    //   primary: '#0096c7',
    //   // secondary: '#5c7f94',
    //   secondary: '#2a4f66',
    //   accent: '#59bbbb',
    //   error: '#FF5252',
    //   info: '#2196F3',
    //   success: '#05c075',
    //   warning: '#FFC107',
    // },

    // light theme colors

    light: {
      textbase: '#424242',
      background: '#ffffff',
      surface: '#f2f5f8',
      primary: '#1976D2',
      secondary: '#193f7d',
      accent: '#d27619',
      error: '#ef476f',
      info: '#2196F3',
      success: '#05c075',
      warning: '#ffd166',
    },

    // light: {
    //   textbase: '#424242',
    //   background: '#ffffff',
    //   surface: '#f2f5f8',
    //   primary: '#0096c7',
    //   secondary: '#2a4f66',
    //   accent: '#59bbbb',
    //   error: '#ef476f',
    //   info: '#2196F3',
    //   success: '#05c075',
    //   warning: '#ffd166',
    // },
  }
};
