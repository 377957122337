import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "./App.vue";
// VUEX - https://vuex.vuejs.org/

// VUE-ROUTER - https://router.vuejs.org/
import router from "./router";

// PLUGINS
import "./plugins/vue-head";
import "./plugins/vue-gtag";

// FILTERS
import "./filters/capitalize";
import "./filters/lowercase";
import "./filters/uppercase";
import "./filters/stringlimit";
import "./filters/marked";

// STYLES
// Main Theme SCSS

// Reactive Forms config
import {
  ClientLibrary,
  ErrorMessageBindingStrategy,
  ReactiveFormConfig
} from "@rxweb/reactive-forms";

// Mitt
import mitt from "mitt";

ReactiveFormConfig.clientLib = ClientLibrary.Vue;

ReactiveFormConfig.set({
  validationMessage: {
    required: "This field is required",
    email: "Not a valid email",
    minLength: "Field must be longer",
    maxLength: "Field must be shorted",
    alpha: "Only alpha character are allowed"
  },
  reactiveForm: {
    errorMessageBindingStrategy: ErrorMessageBindingStrategy.OnDirtyOrSubmit
  }
});

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/

/* Wait for firebase to load before */
Vue.prototype.$bus = mitt();

let app: Vue = new Vue({});
app = new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app");
app.$vuetify.theme.dark = false;
