import icons from './icons';
import theme from './theme';
import analytics from './analytics';

export default {
  // product display information
  product: {
    name: 'AEO_SRI_LANKA',
    version: 'v2.8.0',
  },

  // icon libraries
  icons,

  // theme configs
  themes: theme,

  // analytics configs
  analytics,
};
