import Vue from 'vue';
import Vuetify from 'vuetify';
import theme from "@/configs/theme";

Vue.use(Vuetify);

export default new Vuetify({
  theme,
  options: {
    customProperties: true,
  }
});
