import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta'

Vue.use(Router);
Vue.use(VueMeta);

export const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
    children: [{
      path: '',
      name: 'starter-page',
      component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Home.vue'),
    }
    ],
  },

  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
    children: [{
      path: 'article/:id',
      name: 'article-page',
      component: () => import(/* webpackChunkName: "profile-page" */ '@/pages/Article.vue'),
    }, {
      path: 'articles',
      name: 'articles-page',
      component: () => import(/* webpackChunkName: "profile-page" */ '@/pages/Articles.vue'),
    },
    {
      path: 'privacy',
      name: 'privacy-policy',
      component: () => import(/* webpackChunkName: "profile-page" */ '@/pages/PrivacyPolicy.vue')
    },
    {
      path: 'terms',
      name: 'terms-and-conditions',
      component: () => import(/* webpackChunkName: "profile-page" */ '@/pages/TermsAndConditions.vue')
    },
    // {
    //   path: 'welcome',
    //   name: 'welcome',
    //   component: () => import(/* webpackChunkName: "profile-page" */ '@/pages/Welcome.vue')
    // },
    {
      path: "app/*",
      // component: () => import("@/views/home/Index"),
      meta: {
        authRequired: false
      },
      beforeEnter(to, from, next) {
        // window.location.href = `https://aeo.customs.gov.lk${to.path}`;
        window.location.href = `${to.path}`;
      }
      // children: [
      //   {
      //     name: "Home",
      //     path: "/",
      //     meta: {
      //       authRequired: false
      //     },
      //     component: () => import("@/views/home/Home")
      //   }
      // ]
    },
    ],
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
    children: [{
      path: '',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue'),
    }],
  }];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

export default router;
